/* Add this to your main CSS file */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: auto 40px;
  font-family: "Inter", sans-serif;
}

.mb-5 {
  margin-bottom: 0 !important;
}
.orig {
  background: url(../public/images/gron.png) no-repeat center;
  background-position: left 20px top 10px; /*Positioning*/
  background-repeat: no-repeat;
}
.tabbs {
  background: url(../public/images/gron.png) no-repeat center;
  background-position: right 20px top 10px; /*Positioning*/
  background-repeat: no-repeat;
}

.im-sect {
  background: url(../public/images/gron.png) no-repeat center;
  background-position: left 10px top 10px; /*Positioning*/
  background-repeat: no-repeat;
  border-top-left-radius: 10%;
}
.imagesect {
  padding: 60px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.abb {
  overflow: hidden;
}

.navbar {
  background-color: black;
}

li {
  list-style-type: none;
}

.intro-img {
  border: 3px solid green;
  border-radius: 25px;
}

.or-divider {
  border-top: 2px solid rgba(128, 128, 128, 0.227);
  text-align: center;
  position: relative;
  height: 15px;
}

.or-divider span {
  top: -0.8em;
  position: relative;
  padding: 0 0.5em;
  background: #fff;
  color: black;
  font-size: 35px;
}

.hover-container {
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
}

/* Define a class for the default content */
.default-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border-bottom: 20px;
  border-radius: 20px;
  color: #007bff;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
}

/* Define a class for the hover content */
.hover-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgba(2, 104, 2, 0.813);
  color: #fff;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.hover-container:hover .default-content {
  opacity: 0;
}

.hover-container:hover .hover-content {
  opacity: 1;
}

.over-container {
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
}

.over-container:hover .default-content {
  opacity: 0;
}

.over-container:hover .hover-content {
  opacity: 1;
}

.ver-container {
  background-position: center;
  background-size: cover;
  position: relative;
  width: 240px;
  height: 250px;
  border: 1px solid #ccc;
  overflow: hidden;
  border-radius: 10px;
}

.ver-container:hover .default-content {
  opacity: 0;
}

.ver-container:hover .hover-content {
  opacity: 1;
}

.cycle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.er-container {
  background-position: center;
  background-size: cover;
  position: relative;
  width: 30%;
  height: 350px;
  border: 1px solid #ccc;
  border-radius: 10px;
}
.hoo p {
  font-size: 0.75rem;
}

.er-container:hover .default-content {
  opacity: 0;
}

.er-container:hover .hover-content {
  opacity: 1;
}

.form-control {
  border-radius: 10px !important;
  border: 1px solid green !important;
  background-color: white !important;
  color: #868686 !important;
}
.reg {
  background-image: url(../public/images/image13.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.for-divider {
  border-top: 6px solid #000;
  text-align: center;
  position: relative;
  height: 15px;
  width: 100%;
}

.for-divider span {
  top: -0.8em;
  right: 8em;
  position: relative;
  padding: 0 0.5em;
  background: #fff;
  color: black;
  font-size: 35px;
}

.cour {
  border-top: 6px solid #000;
  text-align: center;
  position: relative;
  height: 15px;
  width: 100%;
  display: none;
  padding-bottom: 10px;
}

.cour span {
  top: -0.8em;
  right: 8em;
  position: relative;
  padding: 0 0.5em;
  background: #fff;
  color: black;
  font-size: 35px;
}

.fore-divider {
  border-top: 6px solid #000;
  text-align: center;
  position: relative;
  height: 15px;
}

.fore-divider span {
  top: -0.8em;
  left: 8em;
  position: relative;
  padding: 0 0.5em;
  background: #fff;
  color: black;
  font-size: 35px;
}

.our {
  display: none;
}
a {
  text-decoration: none;
  color: inherit;
}

.accordion {
  max-width: 100%;
}

.accordion .contentBx {
  position: relative;
  margin: 10px 20px;
}

.accordion .contentBx .label {
  position: relative;
  padding: 10px;
  color: #000;
}

.accordion .contentBx .label::before {
  content: "+";
  color: green;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 1.5em;
}

.accordion .contentBx.active .label::before {
  content: "-";
}

.accordion .contentBx .content {
  position: relative;
  background: #fff;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  overflow-y: auto;
}

.accordion .contentBx.active .content {
  height: 120px;
  padding: 10px;
}

.label {
  background-color: #fff;
  border: 1px solid gray;
}

.swiper-pagination-bullet {
  background: rgb(3, 164, 3) !important;
}

.form-check-input:checked {
  background-color: green !important;
}

.form-check-input {
  background-color: #f1f1f1;
  border: none;
}

.form-check-input:active {
  border: none !important;
}

.form-select {
  background-color: white !important;
  border: 1px solid green !important;
  border-radius: 10px !important;
}

.app-wrap {
  overflow: hidden;
}

.formpara {
  margin: 24% 10%;
}

/* .swiper-button-next {
  top: 98% !important;
  border-radius: 50%;
  color: green;
  border: 1px solid black;
  height: 30px !important;
  width: 30px !important;
  right: 43%;
}

.swiper-button-next:after {
  line-height: 0.5 !important;
  font-family: swiper-icons;
  font-size: 15px;
  font-weight: bolder;
}

.swiper-button-prev {
  top: 98% !important;
  border-radius: 50%;
  color: green;
  border: 1px solid black;
  height: 30px !important;
  width: 30px !important;
  left: 43%;
} */

/* .swiper-button-prev:after {
  line-height: 0.5 !important;
  font-family: swiper-icons;
  font-size: 15px;
  font-weight: bolder;
} */

.swiper-button-next,
.swiper-button-prev {
  display: none;
}

/* text-sizes */
.banner h1 {
  font-size: 3.2rem;
  font-weight: 600;
}
.orig h1 {
  font-size: 3.5rem;
}
.navbar h1 {
  font-size: 230%;
}
.cardi-text,
.faq,
.introspan {
  font-size: 3.5rem;
}
.hire {
  font-size: 2.8rem;
}

.contenti h3 {
  font-size: 3rem;
  margin-bottom: 20px;
  font-weight: 600;
}
.aboout img {
  position: absolute;
  width: 340px;
  height: 340px;
  z-index: 1000;
  right: 50px;
  bottom: 230px;
  border: 4px solid green;
}

.contenti p {
  font-size: 0.9rem;
}
/* text size end */

.crid {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 30px;
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: auto auto;
}

#team h1 {
  font-size: 3.6rem;
}
.value h1 {
  font-size: 3.6rem;
}

.team {
  display: flex;
}

.join-wrap {
  overflow: hidden;
}

.cod {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.codi {
  flex: 1;
  min-width: 0;
  width: 50%;
  margin: auto 30px;
}

.home {
  overflow: hidden;
}

.img-txt {
  display: none;
}

.app-form {
  padding: 50px auto;
}

.footer {
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #000;
  box-sizing: border-box;
  padding-top: 20px;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  padding: 20px;
  flex-wrap: wrap;
}

.footer-column {
  flex: 1;
  padding: 0 15px;
}

.footer-column li {
  color: gray;
  font-size: large;
}
.footer-column li:hover {
  color: green;
  letter-spacing: 0.2px;
}

.footer-column h4 {
  margin-bottom: 10px;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.first-column {
  font-size: larger;
}
.footer-column ul li {
  margin-bottom: 5px;
}

.footer-divider {
  height: 1px;
  background: gray;
  width: 100%;
}

.footer-bottom {
  width: 80%;
}

.footer-bottom-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.footer-bottom-content p {
  margin: 0;
}

.social i {
  font-size: 40px;
  margin: 0 10px;
  color: #006400;
}
.contact-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-wrap {
  overflow: hidden;
}
.detail-wrap {
  overflow: hidden;
}

.opp {
  padding: 0;
  margin: 0;
}

.readMoreClassName {
  color: blue;
  display: block;
}
.readLessClassName {
  color: blue;
  display: block;
}

.process {
  display: none;
}

.contenti {
  width: 60%;
  align-items: center;
  padding-top: 100px;
  padding-left: 60px;
  box-sizing: border-box;
  padding-bottom: 50px;
  justify-self: start;
  color: #fff;
}

/* not working */
.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  flex-direction: column;
  text-align: center;
}
/* end */

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #a5dc86 !important;
  border: none !important;
  box-shadow: none !important;
}
.aboout {
  background-color: #000;
  height: 50vh;
}

.form-select:focus {
  outline: none;
  border-color: #ced4da;
  box-shadow: none;
}

.form-control:focus {
  outline: none;
  border-color: #ced4da;
  box-shadow: none;
}

.form-check .form-check-input:focus {
  outline: none;
  border-color: #ced4da;
  box-shadow: none;
}
.nav-img {
  width: 8%;
}
.ban-img {
  position: relative;
}
.banner {
  width: 100%;
  color: #fff;
  padding: 4%;
}
.baan img {
  width: 20%;
}

.div1 {
  position: absolute;
  left: 35%;
  top: 58%;
}
.divs:hover {
  color: green;
}

.div2 {
  position: absolute;
  left: 34%;
  top: 81%;
}

.div3 {
  margin-top: 10px;
}
.div4 {
  position: absolute;
  right: 36%;
  top: 58%;
}

.div5 {
  position: absolute;
  right: 32%;
  top: 81%;
}
.banner li {
  list-style-type: none;
}
.banner p {
  font-size: 0.9rem;
}

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/* end of footer animation */

.banny {
  width: 100vw;
  height: 100vh;
  background-color: black;
  color: white;
  text-align: center;
  background: black;
  color: white;
  justify-content: center;
  display: flex;
}

.cardi-text {
  margin: 20px 30px;
}
.card-sect {
  display: flex;
  flex-wrap: wrap;
}
.card {
  flex: 1;
  min-width: 0;
  width: 50%;
  margin: auto 30px;
}
.build {
  display: none;
}
.im-sect {
  margin-top: 70px;
}
.swiper-wrapper {
  padding: 0 !important;
}

.open {
  padding: 80px;
}
.formm {
  width: 80%;
}
.form-first {
  width: 50%;
}

.line {
  display: none;
}
.teamcar {
  font-family: "Dancing Script", sans-serif;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #157548;
  color: #fff !important;
}

.team img {
  width: 600px;
  height: 220px;
  border-radius: 50%;
  margin-right: 20px;
}

.slide-image {
  width: 460px;
  height: 500px;
  object-fit: cover;
  display: block;
  margin: 0 auto;
  border-left: 8px solid white;
}

.cad {
  text-align: center;
  padding: 0;
}

.wave-container {
  width: 100%;
  height: auto;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.wavs {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 150px;
}

.enga {
  padding-top: 20px;
}
.enga p {
  width: 98%;
}

/* Animation */

.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

.paralax > use {
  animation: move-foreve 12s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

@keyframes move-foreve {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}
.navbar {
  padding-left: 11%;
  padding-right: 10%;
}

/* media query phone */
@media screen and (max-width: 768px) {
  .fronti {
    margin-top: 80px;
  }
  .enga {
    padding-top: 15px;
  }
  .enga p {
    width: 100%;
  }

  .slide-image {
    border-left: none;
    border-radius: 0;
  }
  .contact-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .nav-img {
    width: 20% !important;
  }
  .banner h1 {
    font-size: 2.3rem;
    font-weight: bold;
    padding: auto 20px;
  }
  .banner p {
    margin: 0 50px;
    font-size: 0.8rem;
    width: 100%;
  }
  .banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 7%;
    width: 100%;
  }

  .baan img {
    width: 200px;
  }
  .div1 {
    left: 13%;
    top: 46%;
  }
  .div2 {
    left: 15%;
    top: 60%;
  }
  .div3 {
    margin-top: 10px;
  }
  .div4 {
    right: 15%;
    top: 46%;
  }
  .div5 {
    right: 10%;
    top: 60%;
  }

  .im-div {
    margin-top: 30px;
    margin-bottom: 40px;
  }

  /* about banner */
  .aboout img {
    width: 200px;
    height: 200px;
    right: 7px;
    bottom: 200px;
  }
  .contenti {
    width: 50%;
    padding-top: 180px;
    padding-bottom: 40px;
    padding-left: 10px;
  }
  .contenti h3 {
    font-size: 1.6rem;
  }
  .contenti p {
    font-size: 0.8rem;
  }
  .formmob {
    padding: 20px;
  }

  /* footer */
  .footer-bottom {
    width: 100%;
  }
  .footer {
    box-sizing: border-box;
    height: 100%;
  }
  .footer-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .first-column {
    flex-basis: 100%;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .footer-column {
    flex-basis: 50%;
  }

  .footer-column:last-child {
    margin-top: 20px;
  }

  .footer-bottom-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-column li {
    font-size: large;
  }

  .social i {
    font-size: 24px;
    color: #006400;
    margin: 0;
  }
  .footer-bottom {
    margin-top: 10px;
  }
  /* footer end */

  .cycle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .aboout {
    height: 50vh;
  }
  .team img {
    width: 260px;
    height: 280px;
    border-radius: 50%;
    margin-right: 20px;
  }

  .detai-img {
    display: none;
  }
  /* banner waves responsive  */
  .wavs {
    height: 40px;
    min-height: 40px;
  }
  /* footer waves  */
  .waves {
    height: 40px;
    min-height: 40px;
  }

  /* application form */
  .form-first {
    width: 100%;
  }
  .app-form {
    padding: 60px 30px;
  }
  .formm {
    width: 100%;
  }
  .co-serve {
    width: 100%;
    /* padding-top: 30px; */
  }

  .detail-div {
    margin: auto 10px;
    align-items: center;
    justify-content: center;
  }
  .for-divider {
    display: none;
  }
  .fore-divider {
    display: none;
  }

  .ourm {
    display: none;
  }
  .our {
    display: block;
    padding-bottom: 20px;
  }
  .our span {
    top: -0.9em;
    right: 2em;
    padding: 0 0.5em;
    font-size: 20px;
  }

  .cour {
    display: block;
    padding-bottom: 40px;
  }
  .cour span {
    top: -0.9em;
    right: 1em;
    padding: 0 0.5em;
    font-size: 20px;
  }

  .p-4 {
    padding: 0;
  }
  .team {
    display: block;
    align-items: center;
    justify-content: center;
    padding: auto 30px;
  }
  #team h1 {
    font-size: 2.5rem;
  }
  .value h1 {
    font-size: 2.5rem;
  }

  .team-text {
    padding: 20px 30px;
  }
  .crid {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }

  /* tab section */
  .ser {
    width: 50%;
  }
  .tabb {
    padding: 9px;
  }
  .eng {
    width: 50%;
  }
  .ul-tab {
    display: flex;
    padding: 9px;
    align-items: center;
    justify-content: center;
  }
  .ind {
    width: 100%;
  }
  /* tab end */
  .line {
    display: block;
  }
  /* font size */

  .orig h1 {
    font-family: "Inter", sans-serif;
    font-size: 1.7rem;
  }

  .buil {
    display: none;
  }
  .build {
    display: block;
  }

  .navbar h1 {
    font-size: 200%;
  }
  .cardi-text,
  .faq,
  .introspan {
    font-size: 2rem;
  }
  .hire {
    font-size: 2rem;
  }

  /* font size end */
  .cod {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }

  .first-crid {
    order: 2;
  }

  .second-crid {
    order: 1;
  }

  .third-crid {
    order: 3;
  }

  .fourth-crid {
    order: 4;
  }

  .img-txt {
    display: block;
    padding-bottom: 14px;
  }

  .first-crid h4 {
    display: none;
  }

  .fourth-crid h4 {
    display: none;
  }

  .slide-div {
    max-width: 100%;
    align-items: center;
    padding: 15px !important;
  }
  .afterform {
    display: none;
  }
  .co {
    flex-basis: 100%;
  }

  .process-lg {
    display: none;
  }

  .process {
    display: block;
  }
  .front {
    margin-top: 20px;
  }
  .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
    top: 0 auto !important;
  }
  .card {
    margin-bottom: 40px;
  }
  .cardi-text {
    margin: 10px 15px;
  }
  .card-sect .card {
    flex-basis: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 10px 15px;
  }

  .cod .codi {
    flex-basis: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 10px 15px;
  }

  .imagesect {
    flex-direction: column;
    padding: 20px;
  }

  .coo {
    width: 100%;
    padding: 3%;
  }

  .co {
    width: 100%;
    margin: 10px auto;
    text-wrap: wrap;
  }

  .er-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .open {
    padding: 20px;
    padding-top: 70px;
    padding-bottom: 60px;
  }

  .social {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .so {
    padding-right: 10px;
  }
}

/* responsive second batch tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .cycle div {
    flex: 1;
  }
  .cycle .er-container {
    flex: 1;
  }
  .fronti {
    margin-top: 10px;
  }
  .detai-img {
    display: none;
  }
  .banner {
    padding-top: 10%;
  }

  .banner h1 {
    font-size: 2.3rem;
    font-weight: bold;
    padding: auto 20px;
  }
  .baan img {
    width: 35%;
  }

  .div1 {
    left: 25%;
    top: 43%;
  }
  .div2 {
    left: 22%;
    top: 64%;
  }
  .div3 {
    margin-top: 20px;
  }
  .div4 {
    right: 25%;
    top: 43%;
  }
  .div5 {
    right: 22%;
    top: 64%;
  }
  .hoo {
    font-size: 0.65rem;
  }

  .aboout img {
    width: 270px;
    height: 270px;
    right: 10px;
    bottom: 200px;
  }
  .aboout {
    height: 50vh;
  }
  .contenti {
    width: 60%;
    padding-top: 170px;
    padding-left: 30px;
    padding-bottom: 50px;
  }
  .contenti h3 {
    font-weight: 600;
    font-size: 3.5rem;
  }
  .contenti p {
    font-size: 1.1rem;
  }

  .detail-div {
    margin: auto 20px;
    align-items: center;
    justify-content: center;
  }
  .for-divider {
    display: none;
  }
  .fore-divider {
    display: none;
  }
  .im-div {
    font-size: 1.1rem;
  }

  .cod {
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
  }

  .cod .codi {
    flex-basis: 100%;
    display: block;
    justify-content: center;
    align-items: center;
    margin: 10px 15px;
  }

  .cour {
    display: block;
    padding-bottom: 40px;
  }
  .cour span {
    top: -0.9em;
    right: 1em;
    padding: 0 0.5em;
    font-size: 20px;
  }
  .first-cridi {
    order: 2;
  }
  .nav-img {
    width: 9%;
  }

  /* footer */
  .footer-bottom {
    width: 100%;
  }
  .footer {
    box-sizing: border-box;
    height: 100%;
  }
  .footer-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .first-column {
    flex-basis: 100%;

    margin-top: 40px;
    margin-bottom: 20px;
  }

  .footer-column {
    flex-basis: 50%;
  }

  .footer-column:last-child {
    margin-top: 20px;
  }

  .footer-bottom-content {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .footer-column li {
    font-size: large;
  }

  .social i {
    font-size: 24px;
    color: #006400;
    margin: 0;
  }
  .footer-bottom {
    margin-top: 10px;
  }
  /* footer end */
}

/* responsive small laptops 100% */
@media screen and (min-width: 1024px) {
  .footer-column li {
    color: gray;
    font-size: 1rem;
  }
  .aboout img {
    position: absolute;
    width: 240px;
    height: 240px;
    z-index: 1000;
    right: 13px;
    bottom: 240px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1rem;
  }

  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 0.75rem;
  }
  .fronti {
    margin-top: 20px;
  }

  .banner {
    padding-top: 4%;
  }
  .nav-img {
    width: 12%;
  }
  .baan img {
    width: 26%;
  }

  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.1em;
    font-family: "Inter", sans-serif;
  }
  .banner {
    width: 100%;
    color: #fff;
    margin-top: 1%;
  }
  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.3rem;
    margin-top: 3%;
  }

  .div1 {
    position: absolute;
    left: 30%;
    top: 48%;
  }

  .div2 {
    position: absolute;
    left: 28%;
    top: 65%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 30%;
    top: 48%;
  }

  .div5 {
    position: absolute;
    right: 25%;
    top: 66%;
  }
}

/* responsiv */
@media screen and (min-width: 1100px) {
  .footer-column li {
    color: gray;
    font-size: 1rem;
  }
  .aboout img {
    position: absolute;
    width: 240px;
    height: 240px;
    z-index: 1000;
    right: 13px;
    bottom: 140px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1rem;
  }

  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 0.75rem;
  }
  .fronti {
    margin-top: 20px;
  }

  .banner {
    padding-top: 2%;
  }
  .nav-img {
    width: 12%;
  }
  .baan img {
    width: 23%;
  }

  .banner h1 {
    font-size: 3rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 0.85em;
    font-family: "Inter", sans-serif;
  }
  .banner {
    width: 100%;
    color: #fff;
    margin-top: 1%;
  }
  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 0.95rem;
    margin-top: 0;
  }

  .div1 {
    position: absolute;
    left: 37%;
    top: 51%;
  }

  .div2 {
    position: absolute;
    left: 36%;
    top: 71%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 37%;
    top: 51%;
  }

  .div5 {
    position: absolute;
    right: 32%;
    top: 71%;
  }
}

/* responsive 80%*/
@media screen and (min-width: 1400px) {
  .baan img {
    width: 24%;
  }

  .div1 {
    position: absolute;
    left: 37%;
    top: 48%;
  }

  .div2 {
    position: absolute;
    left: 35%;
    top: 67%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 37%;
    top: 48%;
  }

  .div5 {
    position: absolute;
    right: 31%;
    top: 67%;
  }
  .banner li {
    list-style-type: none;
  }
  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.1em;
    font-family: "Inter", sans-serif;
  }
  .banner {
    width: 100%;
    color: #fff;
    margin-top: 1%;
  }
  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.2rem;
    margin-top: 0;
  }
  .aboout {
    background-color: #000;
    height: 50vh;
  }
  .aboout img {
    position: absolute;
    width: 270px;
    height: 270px;
    z-index: 1000;
    right: 30px;
    bottom: 200px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 600;
  }
  .contenti p {
    font-size: 1.2rem;
  }
  .introspan {
    font-size: 4rem;
  }
  .nav-img {
    width: 12%;
  }
  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 0.85rem;
  }
  .fronti {
    margin-top: 20px;
  }
}

/* responive extra large 75%*/
@media screen and (min-width: 1500px) {
  .baan img {
    width: 20%;
  }

  .div1 {
    position: absolute;
    left: 37%;
    top: 51%;
  }

  .div2 {
    position: absolute;
    left: 35%;
    top: 70%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 37%;
    top: 51%;
  }

  .div5 {
    position: absolute;
    right: 32%;
    top: 70%;
  }
  .banner li {
    list-style-type: none;
  }
  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.2em;
    font-family: "Inter", sans-serif;
  }
  .banner {
    width: 100%;
    color: #fff;
    margin-top: 1%;
  }
  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.4rem;
    margin-top: 0;
  }
  .aboout {
    background-color: #000;
    height: 50vh;
  }
  .aboout img {
    position: absolute;
    width: 265px;
    height: 265px;
    z-index: 1000;
    right: 30px;
    bottom: 240px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1.2rem;
  }
  .introspan {
    font-size: 4rem;
  }
  .nav-img {
    width: 13%;
  }
  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 0.85rem;
  }
  .fronti {
    margin-top: 20px;
  }
}

/* responsive xxxlargest 67%*/
/* @media screen and (min-width: 1630px) {
  .banner {
    padding-top: 3%;
  }
  .baan img {
    width: 95%;
  }
  .nav-img {
    width: 9%;
  }

  .div1 {
    position: absolute;
    left: 37%;
    top: 49%;
  }

  .div2 {
    position: absolute;
    left: 35%;
    top: 72%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 37%;
    top: 49%;
  }

  .div5 {
    position: absolute;
    right: 30%;
    top: 72%;
  }
  .banner li {
    list-style-type: none;
  }
  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.4em;
    font-family: "Inter", sans-serif;
  }

  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.6rem;
    margin-top: 0;
  }
  .aboout {
    background-color: #000;
    height: 50vh;
  }
  .aboout img {
    position: absolute;
    width: 295px;
    height: 295px;
    z-index: 1000;
    right: 40px;
    bottom: 280px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1.2rem;
  }
  .introspan {
    font-size: 4rem;
  }

  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo p {
    font-size: 0.85rem;
  }
  .fronti {
    margin-top: 20px;
  }
} */

/* responsive 50% */
@media screen and (min-width: 1700px) {
  .formpara {
    margin: 49% 10%;
  }
  .baan img {
    width: 26%;
  }
  .nav-img {
    width: 9%;
  }

  .div1 {
    position: absolute;
    left: 39%;
    top: 39%;
  }

  .div2 {
    position: absolute;
    left: 38%;
    top: 56%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 39%;
    top: 39%;
  }

  .div5 {
    position: absolute;
    right: 35%;
    top: 56%;
  }
  .banner {
    padding-top: 6%;
  }

  .banner li {
    list-style-type: none;
  }
  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.2em;
    font-family: "Inter", sans-serif;
  }

  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.6rem;
    margin-top: 3%;
  }
  .aboout {
    background-color: #000;
    height: 50vh;
  }
  .aboout img {
    position: absolute;
    width: 395px;
    height: 395px;
    z-index: 1000;
    right: 120px;
    bottom: 350px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1.2rem;
  }
  .introspan {
    font-size: 4rem;
  }

  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    padding-top: 170px;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 0.9rem;
  }
  .fronti {
    margin-top: 20px;
  }
}

/* responsive 30%*/

@media screen and (min-width: 2400px) {
  .baan img {
    width: 27%;
  }
  .nav-img {
    width: 9%;
  }

  .div1 {
    position: absolute;
    left: 40%;
    top: 36%;
  }

  .div2 {
    position: absolute;
    left: 40%;
    top: 50%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 40%;
    top: 36%;
  }

  .div5 {
    position: absolute;
    right: 38%;
    top: 50%;
  }
  .banner {
    padding-top: 10%;
  }

  .banner li {
    list-style-type: none;
  }
  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.4em;
    font-family: "Inter", sans-serif;
  }

  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.6rem;
    margin-top: 7%;
  }
  .aboout {
    background-color: #000;
    height: 50vh;
  }
  .aboout img {
    position: absolute;
    width: 530px;
    height: 530px;
    z-index: 1000;
    right: 140px;
    bottom: 600px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1.2rem;
  }
  .introspan {
    font-size: 4rem;
  }

  .contenti {
    width: 80%;
    align-items: center;
    padding-top: 270px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-bottom: 50px;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 1.1rem;
  }
  .fronti {
    margin-top: 20px;
  }
}

/* responsive 2560 */
@media screen and (min-width: 2560px) {
  .baan img {
    width: 27%;
  }
  .nav-img {
    width: 9%;
  }

  .div1 {
    position: absolute;
    left: 40%;
    top: 50%;
  }

  .div2 {
    position: absolute;
    left: 40%;
    top: 73%;
  }

  .div3 {
    margin-top: 0px;
  }
  .div4 {
    position: absolute;
    right: 40%;
    top: 50%;
  }

  .div5 {
    position: absolute;
    right: 38%;
    top: 73%;
  }
  .banner {
    padding-top: 2%;
  }

  .banner li {
    list-style-type: none;
  }
  .banner h1 {
    font-size: 4rem;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
  .banner p {
    font-size: 1.4em;
    font-family: "Inter", sans-serif;
  }

  .im-div {
    font-family: "Inter", sans-serif;
    font-size: 1.6rem;
    margin-top: 1%;
  }
  .aboout {
    background-color: #000;
    height: 50vh;
  }
  .aboout img {
    position: absolute;
    width: 330px;
    height: 330px;
    z-index: 1000;
    right: 140px;
    bottom: 250px;
    border: 4px solid green;
  }
  .contenti h3 {
    font-weight: 550;
  }
  .contenti p {
    font-size: 1.2rem;
  }
  .introspan {
    font-size: 4rem;
  }

  .contenti {
    width: 80%;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    color: #fff;
  }
  .hoo {
    font-size: 1.1rem;
  }
  .fronti {
    margin-top: 20px;
  }
}

/* Responsive first row homepage */

@media screen and (min-width: 1340px) {
  .front {
    margin-top: 100px;
  }
}

@media screen and (max-width: 1339px) {
  .front {
    margin-top: 90px;
  }
}

@media screen and (max-width: 850px) {
  .front {
    margin-top: 90px;
  }
}

/* bubbles */
/* bubbles on background */
.colorlib-bubbles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.colorlib-bubbles li {
  position: absolute;
  list-style: none;
  display: block;
  width: 40px;
  height: 40px;
  background-color: #7fb17f72;
  bottom: -160px;
  -webkit-animation: square 10s infinite; /* Adjusted to 10s */
  -moz-animation: square 10s infinite;
  -o-animation: square 10s infinite;
  -ms-animation: square 10s infinite;
  animation: square 10s infinite;
  -webkit-transition-timing-function: linear;
  -moz-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  transition-timing-function: linear;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

.colorlib-bubbles li:nth-child(1) {
  left: 10%;
}

.colorlib-bubbles li:nth-child(2) {
  left: 20%;
  width: 80px;
  height: 80px;
  -webkit-animation-delay: 1s; /* Adjusted to 1s */
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  -ms-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-duration: 8s; /* Adjusted to 8s */
  -moz-animation-duration: 8s;
  -o-animation-duration: 8s;
  animation-duration: 8s;
}

.colorlib-bubbles li:nth-child(3) {
  left: 25%;
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  -ms-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 9s; /* Adjusted to 9s */
  animation-duration: 9s;
}

.colorlib-bubbles li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  -webkit-animation-duration: 10s; /* Adjusted to 10s */
  -moz-animation-duration: 10s;
  -o-animation-duration: 10s;
  -ms-animation-duration: 10s;
  animation-duration: 10s;
  background-color: rgba(255, 255, 255, 0.25);
}

.colorlib-bubbles li:nth-child(5) {
  left: 70%;
}

.colorlib-bubbles li:nth-child(6) {
  left: 80%;
  width: 120px;
  height: 120px;
  -webkit-animation-delay: 1s; /* Adjusted to 1s */
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  -ms-animation-delay: 1s;
  animation-delay: 1s;
  background-color: rgba(255, 255, 255, 0.2);
}

.colorlib-bubbles li:nth-child(7) {
  left: 32%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  -ms-animation-delay: 3s;
  animation-delay: 3s;
}

.colorlib-bubbles li:nth-child(8) {
  left: 55%;
  width: 20px;
  height: 20px;
  -webkit-animation-delay: 12s; /* Adjusted to 12s */
  -moz-animation-delay: 12s;
  animation-delay: 12s;
  -webkit-animation-duration: 15s; /* Adjusted to 15s */
  -moz-animation-duration: 15s;
  animation-duration: 15s;
}

.colorlib-bubbles li:nth-child(9) {
  left: 25%;
  width: 10px;
  height: 10px;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-duration: 20s; /* Adjusted to 20s */
  animation-duration: 20s;
  background-color: rgba(255, 255, 255, 0.3);
}

.colorlib-bubbles li:nth-child(10) {
  left: 90%;
  width: 160px;
  height: 160px;
  -webkit-animation-delay: 7s; /* Adjusted to 7s */
  animation-delay: 7s;
}

@-webkit-keyframes square {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    -moz-transform: translateY(-700px) rotate(600deg);
    -o-transform: translateY(-700px) rotate(600deg);
    -ms-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}

@keyframes square {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-700px) rotate(600deg);
    -moz-transform: translateY(-700px) rotate(600deg);
    -o-transform: translateY(-700px) rotate(600deg);
    -ms-transform: translateY(-700px) rotate(600deg);
    transform: translateY(-700px) rotate(600deg);
  }
}
